import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';
import Layout from 'src/components/layout/layout';
import PayoutsReportTransactions from 'src/components/payouts-report-transactions/payouts-report-transactions';
import SEO from 'src/components/seo';
import { usePrivateRoute } from 'src/utils/auth';

const PayoutsReportTransactionsPage = () => {
  usePrivateRoute();
  return (
    <Router basepath="tournaments/payouts-report/">
      <PayoutsReportTransactionsRoute path="/:payoutsReportId" />
    </Router>
  );
};

interface PayoutsReportTransactionsProps extends RouteComponentProps {
  payoutsReportId?: string;
}

const PayoutsReportTransactionsRoute: React.FC<PayoutsReportTransactionsProps> = ({ payoutsReportId }) => {
  return (
    <Layout>
      <SEO title="Payouts Report Transactions" />
      {payoutsReportId && <PayoutsReportTransactions payoutsReportId={payoutsReportId} />}
    </Layout>
  );
};

export default PayoutsReportTransactionsPage;
